// require("../vendor/typograph/viewer");

import { Controller } from "@hotwired/stimulus";
import { typograph_document } from "../vendor/typograph/viewer";

Stimulus.register(
  "typograph",
  class extends Controller {
    static targets = ["frame", "input"];

    static values = {
      canvasId: String,

      // e.g. Object { hex: "#000000", hsl: "0,0%,0%", rgb: "0,0,0" }
      colorMain1: Object,
      colorMain2: Object,
      colorSub1: Object,
      colorSub2: Object,
      colorSub3: Object,

      // e.g. Object { url: "https://..." }
      fontMain1: Object,
      fontSub1: Object,
      fontSub2: Object
    }

    connect() {
      this.loadEditor();
      this.enableSaveOnSubmit();

      this.element.addEventListener('classlist:changed', this.setDimensions.bind(this));
      this.assignRangeQueue = [];
    }

    disconnect() {
      this.element.removeEventListener('classlist:changed', this.setDimensions.bind(this));
    }

    setDimensions(_event) {
      // make sure we reduce our canvas element ...
      this.canvasElement.style.width = "100px";
      this.canvasElement.style.height = "100px";

      /// ... before we set the frame to whatever is available for us
      this.canvasElement.style.width = this.frameTarget.clientWidth + "px";
      this.canvasElement.style.height = this.frameTarget.clientHeight + "px";

      // notify Typograph about this change, so it can redraw
      if (this.typograph_document) {
          this.typograph_document.resize_main_canvas();
      }
    }

    loadEditor() {
      this.canvasElement = document.createElement("canvas");
      this.canvasElement.id = this.canvasIdValue;
      this.setDimensions()

      this.canvasElement.addEventListener("typograph_ready", (event) => {
        this.editorReady(event);
      });

      this.canvasElement.addEventListener("typograph_document_opened", (event) => {
        this.typographDocumentOpened(event);
      });

      this.canvasElement.addEventListener("typograph_page_images_loaded", (event) => {
        this.typographPageImagesLoaded(event);
      });

      this.frameTarget.appendChild(this.canvasElement);

      let setup = { canvas_id: this.canvasIdValue, document_url: "", user_id: "", viewer_only: true };
      this.typograph_document = new typograph_document(setup);
      window.g_typograph_document = this.typograph_document;
      this.typograph_document.setup_fontmanager();
      this.typograph_document.setup_documentmanager();
      this.typograph_document.setup_scollbars();
      this.typograph_document.setup_zoom();
      this.typograph_document.setup_undo_redo();
      this.typograph_document.setup_documentmousemanager();
      this.typograph_document.setup_menushortcuts();
      this.typograph_document.setup_undomanager();
      this.typograph_document.setup_dialogmanager("typograph_viewer");
    }

    editorReady() {
      this.typograph_document.open_document(JSON.parse(this.inputTarget.value));
    }

    typographDocumentOpened() {
      // Typograph issues a 'typograph_document_opened'-event whenever the document has been loaded. At this point
      // images have not been loaded yet. Images that are loading and are assigned a new url (by using
      // this.updateImagePlaceholders() here) are not updated properly. We'll need to wait with changing images until
      // Typograph has loaded them
      //
      // There is a event 'typograph_page_images_loaded' issued, but that event 1) is not triggered when the document
      // has no images, 2) is not triggered when loading fails, 3) is triggered per page without us knowing what page
      // is related
      //
      // Thus: the implementation of the 'typograph_page_images_loaded'-event is incorrect, but we'll still have to
      // use it for now...

      // this.updateColors();
      // this.updateFonts();
      // this.updateTextVariables();
      // this.observeSelectedFrameChange();
      // this.observeDocumentDirtyStateChanged();
    }

    typographPageImagesLoaded() {
      if (this.imagePlaceholdersApplied) { return; }

      this.imagePlaceholdersApplied = true;
      this.updateImagePlaceholders();
    }
/*
    updateColors() {
      [
        { name: 'main1', value: this.colorMain1Value },
        { name: 'main2', value: this.colorMain2Value },
        { name: 'sub1', value: this.colorSub1Value },
        { name: 'sub2', value: this.colorSub2Value },
        { name: 'sub3', value: this.colorSub3Value }
      ].forEach((color) => {
        if (color.value.rgb) {
          let [r, g, b] = color.value.rgb.split(',')

          this.editor.getObject(`document.colors[${color.name}]`).then((object) => {
            if (object) {
              this.editor.setProperty(`document.colors[${object.id}]`, "type", "RGB").then(() => {
                this.editor.setProperty(`document.colors[${object.id}]`, "r", r).then(() => {
                  this.editor.setProperty(`document.colors[${object.id}]`, "g", g).then(() => {
                    this.editor.setProperty(`document.colors[${object.id}]`, "b", b)
                  })
                })
              })
            }
          });
        }
      })
    }

    updateFonts() {
      [
        { name: 'main1', value: this.fontMain1Value, replaces: 'Maax Regular_Bold' },
        { name: 'sub1', value: this.fontSub1Value, replaces: 'Maax Regular_Regular' },
        { name: 'sub2', value: this.fontSub2Value, replaces: 'Minion Pro_Regular' }
      ].forEach((font) => {
        if (font.value.id) {
          this.editor.getObject(`document.fonts[${font.value.id}]`).then((fontObject) => {
            if (fontObject) {
              this.replaceFont(font);
            } else {
              this.editor.executeFunction('document.fonts', 'Add').then((newFontObject) => {
                this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "family", font.value.family).then(() => {
                  this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "style", font.value.style).then(() => {
                    this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "name", `${font.value.family} ${font.value.style}`).then(() => {
                      this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "id", font.value.id).then(() => {
                        this.replaceFont(font);
                      })
                    })
                  })
                })
              })
            }
          })
        }
      })
    }

    replaceFont(font) {
      this.editor.getObject('document.paragraphStyles').then((paragraphStyles) => {
        let count = paragraphStyles.count;

        for (var index = 0; index < count; index++) {
          this.editor.executeFunction('document.paragraphStyles', 'GetItem', index).then((paragraphStyle) => {
            if (paragraphStyle.HasFormat) {
              this.editor.getObject(`document.paragraphStyles[${paragraphStyle.id}].format`).then((format) => {
                if (format.fontFamily == font.replaces) {
                  this.editor.setProperty(
                    `document.paragraphStyles[${paragraphStyle.id}].textFormat`,
                    "font",
                    `cp_object:document.fonts[${font.value.id}]`
                  );
                }
              })
            }
          });
        }
      });
    }

    updateTextVariables() {
      this.element
        .querySelectorAll('*[data-action*="pushTextVariable"]')
        .forEach((textVariableInput) => {
          // Pull text variables from Chili into text fields
          // this.editor
          //   .getObject(
          //     `document.variables[${textVariableInput.dataset.typographTargetParam}]`
          //   )
          //   .then((object) => {
          //     textVariableInput.value = object.value;
          //   });

          // Push text variables from text fields into Chili
          this.editor.setProperty(
            `document.variables[${textVariableInput.dataset.typographTargetParam}]`,
            "value",
            textVariableInput.value
          );
        });
    }
*/
    updateImagePlaceholders() {
      // update all placeholders
      let placeholdersNodelist = this.element.querySelectorAll('input[data-action="change->typograph#pushImagePlaceholder"][value]');
      let placeholders = [...placeholdersNodelist];

      placeholders.forEach(placeholder => {
        let uuid = placeholder.attributes['data-typograph-target-param'].value;

        this.typograph_document.pages.forEach(page => {
          let page_element = page.getElementByUUID(uuid);
          if (page_element) {
            var params = {};
            let placeholder_attributes = placeholder.attributes;

            [...placeholder_attributes].forEach(param => {
              let param_match = param.name.match(/^data-typograph-(.*)-param$/);
              if (param_match) {
                params[this.camelize(param_match[1])] = this.typecast(param.value);
              }

              if (param.name == 'data-typograph-current-placeholder') {
                params['placeholder'] = JSON.parse(param.value).data;
              }
            })

            this.pushImagePlaceholderValue(placeholder, params);
          }
        });
      });
    }
/*
    observeSelectedFrameChange() {
      this.editor.addListener("SelectedFrameChanged", (selectedFrameId) => {
        if (selectedFrameId) {
          this.editor
            .getObject(`document.allFrames[${selectedFrameId}]`)
            .then((frame) => {
              if (frame.TextSpan) {
                frame.TextSpan.split(/(%.*?%)/)
                  .filter((s) => /^%.*%$/.test(s))
                  .forEach((textVariable) => {
                    textVariable = textVariable.replace(/^%(.*?)%$/, "$1");
                    this.element
                      .querySelectorAll(
                        `*[data-typograph-target-param="${textVariable}"]`
                      )
                      .forEach((textVariableInput) => {
                        textVariableInput.focus();
                      });
                  });
              } else {
                this.element
                  .querySelectorAll(
                    `*[data-typograph-target-param="${frame.id}"]`
                  )
                  .forEach((input) => {
                    if (
                      input.classList.contains("image-selection-placeholder")
                    ) {
                      let select_button = input.parentElement.querySelector(".select-or-upload-btn") ||
                        input.parentElement.querySelector(".select-btn");

                      // a click is bubbled through to the body and immediatly closes the dialog again
                      // select_button.click();
                      $(select_button.dataset.target).modal("show");
                    }
                  });
              }
            });
        }
      });
    }

    pushTextVariable(event) {
      this.editor.setProperty(
        `document.variables[${event.params.target}]`,
        "value",
        event.target.value
      );
    }
*/
    pushImagePlaceholder(event) {
      let placeholder = event.target;
      let params = event.params;

      this.pushImagePlaceholderValue(placeholder, params);
    }

    pushImagePlaceholderValue(placeholder, params) {
      let target = params.target;
      let value = placeholder.value;

      if (params.rangeSelect) {
        let queueItem = {
          placeholder: placeholder,
          params: params
        }

        if (this.assignRangeQueue.filter(queuedItem => queuedItem.placeholder.id == queueItem.placeholder.id).length == 0) {
          this.assignRangeQueue[this.assignRangeQueue.length] = queueItem;
          this.processAssignRangeQueue();
        }
      } else if (params.placeholder) {
        let placeholder = params.placeholder;
        let url = this.populateUrlParams(params.url, placeholder, value);

        this.applyImagePlaceholderValue({
          valuePresent: !!value,
          target: target,
          url: url
        });
      }
    }

    processAssignRangeQueue() {
      if (this.pushImagePlaceholderRangeValueRunning) {
        return;
      }

      let nextItem = this.assignRangeQueue.pop();

      if (nextItem) {
        this.pushImagePlaceholderRangeValue(nextItem.placeholder, nextItem.params);
      } else {
        this.pushImagePlaceholderRangeValueRunning = false;
      }
    }

    pushImagePlaceholderRangeValue(placeholder, params) {
      this.pushImagePlaceholderRangeValueRunning = true;
      let target = params.target;
      let value = placeholder.value;

      fetch(
        placeholder.dataset.typographRangeAssignPath,
        {
          method: 'PATCH',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: JSON.stringify({
            authenticity_token: this.element.querySelector('input[name="authenticity_token"]').value,
            value: value,
          }),
        }
      ).then((response) => response.json())
        .then((json) => {
          let file = json.files[0];

          if (!file) {
            this.applyImagePlaceholderValue({
              valuePresent: false,
              target: target
            });

            this.pushImagePlaceholderRangeValueRunning = false;
            this.processAssignRangeQueue();
            return
          }

          let placeholder = file.image_library_link_attributes.data;
          let url = this.populateUrlParams(params.url, placeholder, file.name);

          this.applyImagePlaceholderValue({
            valuePresent: true,
            target: target,
            url: url
          });

          this.pushImagePlaceholderRangeValueRunning = false;
          this.processAssignRangeQueue();
        });
    }

    applyImagePlaceholderValue({ valuePresent, target, url }) {
      this.typograph_document.pages.forEach(page => {
        let pageElement = page.getElementByUUID(target);
        if (pageElement) {
          if (valuePresent) {
            pageElement.hidden = false;
            pageElement.set_image_scale('ScaleToFit');
            pageElement.set_url(url);
          } else {
            pageElement.hidden = true;
            this.typograph_document.redraw();
          }
        }
      });
    }

    populateUrlParams(url, placeholder, value) {
      return url
        .replaceAll("/:s/", `/${encodeURIComponent(placeholder.secret)}/`)
        .replaceAll("/:path", `/${encodeURIComponent(value)}`);
    }
/*
    setAlternateLayout({ params: { target } }) {
      this.editor.setProperty(
        `document.variables[SetAlternateLayout]`,
        "value",
        target
      );
    }
*/
    enableSaveOnSubmit() {
      this.element.addEventListener("submit", (event) => {
        this.saveAndSubmit(event);
      });
    }

    saveAndSubmit(_event) {
      this.inputTarget.value = this.typograph_document.get_document_data_as_string();

      return true;
    }

    // Taken from https://github.com/hotwired/stimulus/blob/8cbca6db3b1b2ddb384deb3dd98397d3609d25a0/src/core/action.ts
    // because we need to construct event params in updateImagePlaceholders
    camelize(value) {
      return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase());
    }

    typecast(value) {
      try {
        return JSON.parse(value);
      } catch (o_O) {
        return value;
      }
    }
  }
);
